import { ContentfulUiComponentItem } from '@truckmap/common';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { NavbarItem } from 'components/PublicNavigation/NavbarItem';
import { SubNavigation } from 'components/PublicNavigation/SubNavigation';
import { useNavigation } from 'hooks/useNavigation/useNavigation';
import { calculateNavigationStyles } from 'lib/calculateNavigationStyles';
import { makeStyles } from 'lib/makeStyles';
import { useRef } from 'react';

type Props = {
  items: ContentfulUiComponentItem[];
};

export const useStyles = makeStyles({
  navigationContainer: ({ dropDownVisible }) => [
    'absolute top-16 transition-all left-0 invisible will-change-contents duration-200 ease-in-out overflow-hidden mt-1 shadow-lg rounded-lg bg-white',
    {
      'animate-fade-in-300': dropDownVisible,
      'animate-fade-out-300 transition-none': !dropDownVisible
    }
  ]
});

export const NavbarItems = ({ items }: Props) => {
  const { state, handleMouseEnter, handleMouseLeave } = useNavigation();
  const styles = useStyles({ dropDownVisible: state.dropDownVisible });

  const itemRefs = useRef<HTMLDivElement[]>([]);
  const currentRef = itemRefs.current[state.currentIndex];
  const navigationStyles = calculateNavigationStyles({
    currentItems: items[state.currentIndex]?.itemsCollection?.items,
    currentRef
  });

  return (
    <nav className="flex" onMouseLeave={handleMouseLeave}>
      <PanelItem spacing="2XL">
        {items.map((entry, index) => (
          <NavbarItem
            key={entry.sys.id}
            link={entry.primaryLink}
            index={index}
            onMouseEnter={handleMouseEnter}
            onKeyEscape={handleMouseLeave}
            ref={(element) => (itemRefs.current[index] = element)}>
            {entry.primaryText}
          </NavbarItem>
        ))}
      </PanelItem>
      <div className={styles.navigationContainer} style={navigationStyles}>
        <SubNavigation
          item={items[state.currentIndex]}
          index={state.currentIndex}
          currentRef={currentRef}
        />
      </div>
    </nav>
  );
};
