import dynamic from 'next/dynamic';
import { ComponentType } from 'react';
import { MappedItemEntries } from 'types/contentful/MappedItemEntries';
import { SerializedComponent } from 'types/contentful/SerializedComponent';

type Props = {
  entry: SerializedComponent;
  items: MappedItemEntries;
};

export const sectionLayoutsMap = new Map<string, ComponentType<Props>>([
  [
    'about-us-hero',
    dynamic(() =>
      import('components/AboutUs/AboutUsHero/AboutUsHero').then((mod) => mod.AboutUsHero)
    )
  ],
  [
    'about-us-content-images',
    dynamic(() => import('components/AboutUs/AboutUsContent').then((mod) => mod.AboutUsContent))
  ],
  [
    'about-us-investors',
    dynamic(() => import('components/AboutUs/AboutUsInvestors').then((mod) => mod.AboutUsInvestors))
  ],
  [
    'about-us-team',
    dynamic(() => import('components/AboutUs/AboutUsTeam').then((mod) => mod.AboutUsTeam))
  ],
  [
    'common-feature-cards',
    dynamic(() => import('components/contentful/FeatureCards').then((mod) => mod.FeatureCards))
  ],
  [
    'common-section-feature-list',
    dynamic(() =>
      import('components/contentful/SectionFeatureList').then((mod) => mod.SectionFeatureList)
    )
  ],
  [
    'common-text-page',
    dynamic(() => import('components/TextPage/TextPage').then((mod) => mod.TextPage))
  ],
  [
    'error-hero',
    dynamic(() => import('components/ErrorPage/ErrorPageHero').then((mod) => mod.ErrorPageHero))
  ],
  [
    'error-top-search-results-section',
    dynamic(() =>
      import('components/ErrorPage/TopSearchResultsSection').then(
        (mod) => mod.TopSearchResultsSection
      )
    )
  ],
  [
    'error-search-banner',
    dynamic(() =>
      import('components/ErrorPage/ErrorPageSearchBanner').then((mod) => mod.ErrorPageSearchBanner)
    )
  ],
  [
    'homepage-pinpoint',
    dynamic(() =>
      import('components/Homepage/HomepageSectionPinpoint').then(
        (mod) => mod.HomepageSectionPinpoint
      )
    )
  ],
  [
    'homepage-usedby',
    dynamic(() =>
      import('components/Homepage/HomepageSectionUsedBy').then((mod) => mod.HomepageSectionUsedBy)
    )
  ],
  [
    'homepage-journey',
    dynamic(() =>
      import('components/Homepage/HomepageSectionJourney').then((mod) => mod.HomepageSectionJourney)
    )
  ],
  [
    'homepage-reviews',
    dynamic(() =>
      import('components/Homepage/HomepageSectionReviews').then((mod) => mod.HomepageSectionReviews)
    )
  ],
  [
    'homepage-download',
    dynamic(() =>
      import('components/Homepage/HomepageSectionDownload').then(
        (mod) => mod.HomepageSectionDownload
      )
    )
  ],
  [
    'homepage-hero',
    dynamic(() => import('components/Homepage/HomepageHero').then((mod) => mod.HomepageHero))
  ],
  [
    'solutions-hero',
    dynamic(() => import('components/Solutions/SolutionsHero').then((mod) => mod.SolutionsHero))
  ],
  [
    'solutions-carriers-turn-by-turn',
    dynamic(() =>
      import('components/Solutions/TruckingCompanies/SolutionsTurnByTurn').then(
        (mod) => mod.SolutionsTurnByTurn
      )
    )
  ],
  [
    'solutions-carriers-road',
    dynamic(() =>
      import('components/Solutions/TruckingCompanies/SolutionsRoadRestricions').then(
        (mod) => mod.SolutionsRoadRestricions
      )
    )
  ],
  [
    'solutions-carriers-fleet',
    dynamic(() =>
      import('components/Solutions/TruckingCompanies/SolutionsFleetManagement').then(
        (mod) => mod.SolutionsFleetManagement
      )
    )
  ],
  [
    'solutions-brokers-driver-tracking',
    dynamic(() =>
      import('components/Solutions/Brokers/SolutionsDriverTracking').then(
        (mod) => mod.SolutionsDriverTracking
      )
    )
  ],
  [
    'solutions-brokers-shipment-filters',
    dynamic(() =>
      import('components/Solutions/Brokers/SolutionsShipmentFilters').then(
        (mod) => mod.SolutionsShipmentFilters
      )
    )
  ],
  [
    'solutions-warehouse-hero',
    dynamic(() =>
      import('components/Solutions/ShippingFacilities/SolutionsTruckingHero').then(
        (mod) => mod.SolutionsTruckingHero
      )
    )
  ],
  [
    'solutions-warehouse-facility-services',
    dynamic(() =>
      import('components/Solutions/ShippingFacilities/SolutionsFacilityServices').then(
        (mod) => mod.SolutionsFacilityServices
      )
    )
  ],
  [
    'solutions-warehouse-featured-facilities',
    dynamic(() =>
      import('components/Solutions/ShippingFacilities/SolutionsFeaturedFacilities').then(
        (mod) => mod.SolutionsFeaturedFacilities
      )
    )
  ],
  [
    'solutions-warehouse-facility-setup',
    dynamic(() =>
      import('components/Solutions/ShippingFacilities/SolutionsFacilitySetup').then(
        (mod) => mod.SolutionsFacilitySetup
      )
    )
  ],
  [
    'solutions-developers-hero',
    dynamic(() =>
      import('components/Solutions/Developers/SolutionsDevelopersHero').then(
        (mod) => mod.SolutionsDevelopersHero
      )
    )
  ],
  [
    'solutions-developers-section',
    dynamic(() =>
      import('components/Solutions/Developers/SolutionsDevelopersSection').then(
        (mod) => mod.SolutionsDevelopersSection
      )
    )
  ],
  [
    'careers-hero',
    dynamic(() => import('components/Careers/CareersHero').then((mod) => mod.CareersHero))
  ],
  [
    'careers-list',
    dynamic(() =>
      import('components/Careers/CareersSectionList').then((mod) => mod.CareersSectionList)
    )
  ],
  [
    'tenstreet-hero-section',
    dynamic(() =>
      import('components/tenstreet/TenstreetHeroSection').then((mod) => mod.TenstreetHeroSection)
    )
  ],
  [
    'feature-section',
    dynamic(() =>
      import('components/contentful/FeatureSection/FeatureBulletSection').then(
        (mod) => mod.FeatureBulletSection
      )
    )
  ],
  [
    'feature-section-reverse',
    dynamic(() =>
      import('components/contentful/FeatureSection/FeatureBulletSectionReverse').then(
        (mod) => mod.FeatureBulletSectionReverse
      )
    )
  ],
  [
    'cta-section',
    dynamic(() =>
      import('components/contentful/CTASection/CTASection').then((mod) => mod.CTASection)
    )
  ],
  [
    'cta-section-phone-mockup',
    dynamic(() =>
      import('components/contentful/CTASection/CTASectionPhoneMockup').then(
        (mod) => mod.CTASectionPhoneMockup
      )
    )
  ],
  [
    'cta-section-centered',
    dynamic(() =>
      import('components/contentful/CTASection/CTASectionSingleColumn').then(
        (mod) => mod.CTASectionSingleColumn
      )
    )
  ],
  [
    'tenstreet-cta-section',
    dynamic(() =>
      import('components/tenstreet/TenstreetCTA/TenstreetCTA').then((mod) => mod.TenstreetCTA)
    )
  ],
  [
    'tenstreet-cta-section-phone-mockup',
    dynamic(() =>
      import('components/tenstreet/TenstreetCTA/TenstreetCTAPhoneMockup').then(
        (mod) => mod.TenstreetCTAPhoneMockup
      )
    )
  ],
  [
    'tenstreet-cta-section-single-column',
    dynamic(() =>
      import('components/tenstreet/TenstreetCTA/TenstreetCTASingleColumn').then(
        (mod) => mod.TenstreetCTASingleColumn
      )
    )
  ],
  [
    'recruiting-partner-logos',
    dynamic(() =>
      import('components/tenstreet/TenstreetPartnerLogosCarousel').then(
        (mod) => mod.TenstreetPartnerLogosCarousel
      )
    )
  ]
]);

export enum UIComponentItems {
  IMAGE = 'Image',
  FEATURE_BULLET = 'FeatureBullet',
  COLUMN = 'Column',
  BUTTON = 'Button'
}

//tailwindcss.com/docs/configuring-variants
export enum ColorVariants {
  DEFAULT = 'DEFAULT',
  DARK = 'dark'
}

export enum RichTextTypes {
  HEADING = 'heading',
  SUBHEADING = 'subheading',
  CONTENT = 'content',
  TEXT_PAGE_NAVBAR_TITLE = 'textPageNavBarTitle'
}
