import { Button } from 'components/common/Button';
import { Translation } from 'components/common/Translation';
import { HeadingText, SmallText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { UserMenu } from 'components/UserMenu';
import { useRawToggle, useToggle } from 'hooks/useToggle';
import { isFullUser } from 'lib/auth/user';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'recoil/user';

import { AuthForm } from './AuthForm/AuthForm';
import { AuthModalProps } from './AuthModal/AuthModal';
import { AuthTooltip } from './AuthTooltip';

export type AuthGateProps = {
  fullWidthButton?: boolean;
} & Pick<AuthModalProps, 'size' | 'showBack' | 'showClose'>;

const AuthGate = React.memo(
  ({ size = 'sm', fullWidthButton = false, showBack, showClose }: AuthGateProps) => {
    const userState = useRecoilValue(userAtom);
    const [showModal, toggleModal] = useRawToggle(false);
    const {
      status: usernameForm,
      enable: showUsernameForm,
      disable: hideUsernameForm
    } = useToggle(false);

    useEffect(() => {
      if (userState) {
        if (!isFullUser(userState.user)) {
          showUsernameForm();
        } else if (usernameForm) {
          hideUsernameForm();
        }
      } else if (usernameForm) {
        hideUsernameForm();
      }
    }, [userState]);

    return (
      <>
        {!userState ? (
          <Button isPrimary fullWidth={fullWidthButton} onClick={toggleModal} id="login_button">
            <SmallText medium color="white">
              <Translation textKey="BUTTON_LOGIN_SIGNUP" />
              {/*Dashboard*/}
            </SmallText>
          </Button>
        ) : (
          <UserMenu />
        )}
        {size === 'lg' && (
          <AuthForm
            size={size}
            hasPhoneModal={true}
            showUsernameForm={usernameForm}
            showBackButton={!usernameForm}
            showPhoneModal={showModal}
            onClosePhoneModal={toggleModal}
            hasDefaultHeading={false}
            justify="center"
            label={
              <PanelItem align="center">
                <HeadingText size="XS">
                  <Translation textKey="AUTH_LABEL" />
                </HeadingText>
                <AuthTooltip />
              </PanelItem>
            }
          />
        )}
        {size === 'sm' && (
          <AuthForm
            size={size}
            hasPhoneModal={true}
            showBackButton={!usernameForm && showBack}
            showUsernameForm={usernameForm}
            showClose={showClose}
            showPhoneModal={showModal}
            onClosePhoneModal={toggleModal}
            justify="center"
          />
        )}
      </>
    );
  }
);

AuthGate.displayName = 'AuthGate';

export { AuthGate };
